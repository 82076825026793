import React from "react";
import GradientTextWrapper from "components/core/gradientTextWrapper";
import IconComponent from "components/core/icon";
import { Link } from "react-router-dom";
import LYOStaysLogo from "static/images/lyo_stays_logo.png";
import ReSqaureLogo from "static/images/resqaure_logo.png";

const HomepageProducts = () => {
  const PRODUCTS = [
    {
      title: "LYO Stays",
      launched: true,
      url: "https://www.lyostays.com",
      logo: LYOStaysLogo,
    },
    {
      title: "ReSqaure",
      launched: false,
      url: "/products/resqaure",
      logo: ReSqaureLogo,
    },
  ];
  return (
    <div className="relative mt-24 pl-6 pr-6 sm:pl-6 sm:pr-6 md:pl-10 md:pr-10 lg:pl-24 lg:pr-24 font-jost">
      <p className="text-slate-200 font-jost text-xl font-bold sm:text-2xl md:text-4xl lg:text-5xl ">
        {" "}
        Our Products
      </p>
      <p className="text-slate-300 mt-4">
        <span className="">
          Real problems deserves Grounded & Easy-to-use Tech Solutions.
        </span>
      </p>
      <div className="mt-8 grid grid-cols-2 ">
        {PRODUCTS.map((p, idx) => {
          return (
            <div
              key={idx}
              className="mt-4 col-span-2 sm:col-span-2 md:col-span-1"
            >
              <div className="max-w-sm p-6 backdrop-blur-md backdrop-brightness-100 border border-slate-800 rounded-lg shadow  ">
                <img src={p.logo} alt="Lyo Stays Logo" className="w-12 h-12 " />
                <a href="#" className="">
                  <h5 className="mb-2 text-2xl mt-2 font-semibold tracking-tight text-white">
                    {p.title}
                  </h5>
                </a>
                <Link
                  to={p.url}
                  className="inline-flex justify-center items-center text-blue-600 hover:underline"
                >
                  <span>Read More</span>
                  <IconComponent
                    nameIcon="BsArrowRight"
                    propsIcon={{ className: "w-5 h-5 ml-2" }}
                  />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomepageProducts;
