import React, { UIEvent, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// Component Import
import HomePage from "pages/home";
import RootLayout from "layouts/rootLayout";
import { useAppSelector, useAppDispatch } from "hooks/redux/rootStoreHooks";
import "./app.css";
import {
  changeNavigationTransparecy,
  setNavActiveOption,
  setUIDark,
  setUILight,
} from "reducers/rootReducer";
import BlogsPage from "pages/blogs";
import VisionPage from "pages/vision";
import ContactUsPage from "pages/contactUs";
import ReSqaureProductPage from "pages/resqaure";
import AboutTeamPage from "pages/aboutTeam";
import { useSystemTheme } from "hooks/app/useSystemTheme";
import HomePageV2 from "pages/home/indexV2";

type AppProps = {};

const App: React.FC = (props: AppProps) => {
  const isDarkMode = useAppSelector((state) => state.root.ui.isDarkMode);
  const dispatch = useAppDispatch();
  const currentSystemTheme = useSystemTheme();

  const currentPathname = window.location.pathname.split("/")[1].toLowerCase();
  const routerV2 = createBrowserRouter([
    {
      path: "/",
      element: <HomePageV2 />,
    },
  ]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        { path: "", element: <HomePage /> },
        { path: "blogs", element: <BlogsPage /> },
        { path: "vision", element: <VisionPage /> },
        { path: "contact_us", element: <ContactUsPage /> },
        { path: "products/resqaure", element: <ReSqaureProductPage /> },
        { path: "about_team", element: <AboutTeamPage /> },
      ],
    },
  ]);
  useEffect(() => {
    dispatch(setNavActiveOption(currentPathname));
    if (currentSystemTheme === "dark") {
      dispatch(setUIDark());
    } else {
      dispatch(setUILight());
    }
  }, []);

  const handleUIScroll = (e: UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (target.scrollTop >= 20) {
      dispatch(changeNavigationTransparecy({ isTransparent: false }));
    } else {
      dispatch(changeNavigationTransparecy({ isTransparent: true }));
    }
  };

  return (
    <div
      onScroll={handleUIScroll}
      className={`${"dark"} w-full h-full overflow-x-hidden`}
    >
      <RouterProvider router={routerV2} />
    </div>
  );
};

export default App;
