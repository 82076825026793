import { useAppDispatch, useAppSelector } from "hooks/redux/rootStoreHooks";
import React, { useEffect } from "react";
import { setUIDark, setUILight } from "reducers/rootReducer";
import IconComponent from "../../../icon";
interface IHeaderThemeComponentProps {
  wrapperClassName: string;
}
const HeaderThemeSwitcherComponent = ({
  wrapperClassName,
  ...props
}: IHeaderThemeComponentProps) => {
  const isDarkMode = useAppSelector((state) => state.root.ui.isDarkMode);
  const dispatch = useAppDispatch();
  return (
    <div className={wrapperClassName}>
      {!isDarkMode ? (
        <a
          href="#"
          onClick={() => {
            localStorage.setItem("theme", "dark");
            dispatch(setUIDark());
          }}
          className="cursor-pointer"
        >
          <IconComponent
            nameIcon="BsMoon"
            propsIcon={{
              className: "text-slate-600 cursor-pointer text-xl md:text-4xl ",
            }}
          />
        </a>
      ) : (
        <a
          href="#"
          onClick={() => {
            localStorage.setItem("theme", "light");
            dispatch(setUILight());
          }}
        >
          <IconComponent
            nameIcon="BiSun"
            propsIcon={{
              className: "text-slate-600 text-xl md:text-4xl cursor-pointer",
            }}
          />
        </a>
      )}
    </div>
  );
};

export default HeaderThemeSwitcherComponent;
