import React, { ReactNode } from "react";

interface IPrimaryCTAProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const PrimaryCTA = ({ children, ...props }: IPrimaryCTAProps) => {
  return (
    <button
      className={`relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 ${props.className}`}
    >
      <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-800  rounded-md group-hover:bg-opacity-0">
        {children}
      </span>
    </button>
  );
};

export default PrimaryCTA;
