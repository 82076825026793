import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "stores/rootStore";

interface IMenuOptions {
  title: string;
  id: string;
  redirectURL: string;
}

interface IMobileMenuOptions extends IMenuOptions {
  icon: string;
}

interface INavigation {
  isTransparentBackground: boolean;
  activeOption: string;
  mobileOptions: Array<IMobileMenuOptions>;
  options: Array<IMenuOptions>;
}

interface IRootState {
  ui: {
    isMobile: boolean;
    isDarkMode: boolean;
  };
  navigation: INavigation;
}

const initialState: IRootState = {
  ui: {
    isMobile: false,
    isDarkMode: true,
  },
  navigation: {
    isTransparentBackground: true,
    activeOption: "home",
    mobileOptions: [
      { title: "Home", id: "home", redirectURL: "", icon: "TbSmartHome" },
      {
        title: "Blogs",
        id: "blogs",
        redirectURL: "blogs",
        icon: "HiOutlineNewspaper",
      },
      {
        title: "Contact",
        id: "contact_us",
        redirectURL: "contact_us",
        icon: "BiMailSend",
      },
      {
        title: "Vision",
        id: "vision",
        redirectURL: "vision",
        icon: "CgInfinity",
      },
      {
        title: "Team",
        id: "about_team",
        redirectURL: "about_team",
        icon: "AiOutlineTeam",
      },
    ],
    options: [
      { title: "Home", id: "home", redirectURL: "/" },
      { title: "About Team", id: "about_team", redirectURL: "about_team" },
      { title: "Vision", id: "vision", redirectURL: "/vision" },
      { title: "Contact Us", id: "contact_us", redirectURL: "/contact_us" },
      { title: "Blogs", id: "blogs", redirectURL: "/blogs" },
    ],
  },
};

type ThemePayloadActionObject = {
  isTransparent: boolean;
};

export const rootSlice = createSlice({
  name: "root",
  initialState: initialState,
  reducers: {
    setUIDark: (state) => {
      state.ui.isDarkMode = true;
    },
    setUILight: (state) => {
      state.ui.isDarkMode = false;
    },
    setNavActiveOption: (state, action: PayloadAction<string>) => {
      state.navigation.activeOption = action.payload;
    },
    changeNavigationTransparecy: (
      state,
      action: PayloadAction<ThemePayloadActionObject>
    ) => {
      state.navigation.isTransparentBackground = action.payload.isTransparent;
    },
  },
});

export const {
  setUIDark,
  setUILight,
  changeNavigationTransparecy,
  setNavActiveOption,
} = rootSlice.actions;
// export const selectCount = (state: RootState) => state.root.counter;
export default rootSlice.reducer;
