import React, { useEffect, useState } from "react";

const MOBILE_VIEWPORT_WIDTH = 550;

export const useMobile = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return width <= MOBILE_VIEWPORT_WIDTH;
};
