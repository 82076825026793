import React from "react";
// Components imports
import HeaderComponent from "components/core/header";
import PrimaryCTA from "components/core/buttons/pirmaryCTA";
import FooterComponent from "components/core/footer";
import IconComponent from "components/core/icon";
import HomepageMission from "./sections/mission";
import HomepageProducts from "./sections/products";
//static imports
import BackdropVideo from "static/videos/backdrop.mp4";

interface IHomePageProps {}

const HomePage: React.FC = (props: IHomePageProps) => {
  return (
    <>
      {" "}
      <div className="relative ">
        <div className="w-screen h-screen">
          <video
            src={BackdropVideo}
            autoPlay={true}
            id="backdrop_video"
            muted
            loop
            playsInline
            preload="false"
            className="w-full overflow-hidden opacity-30 m-0 -z-10 h-full absolute  top-0 left-0 object-cover  "
          ></video>
        </div>
        <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 justify-center items-center flex-col">
          <div className="grid grid-cols-2">
            <div className="text-center col-span-2 flex justify-center items-center flex-col">
              <div className="font-jost">
                <p className="text-slate-200  font-bold text-6xl sm:text-6xl md:text-8xl lg:text-10xl">
                  LYO Spaces
                </p>
                <p className="text-slate-300 mt-8 text-lg sm:mt-8 md:mt-6 lg:mt-4 ">
                  RE-Imagining Real Estate Technologies
                </p>
                <div className="mt-4">
                  <PrimaryCTA>
                    Explore
                    <IconComponent
                      nameIcon="BsArrowRight"
                      propsIcon={{ className: "text-white inline-block ml-1" }}
                    />
                  </PrimaryCTA>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomepageMission />
      <HomepageProducts />
    </>
  );
};

export default HomePage;
