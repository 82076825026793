import React from "react";
import { Outlet } from "react-router-dom";
//compoenent imports
import HeaderComponent from "components/core/header";
import FooterComponent from "components/core/footer";
interface IRootLayoutProps {}

const RootLayout = (props: IRootLayoutProps) => {
  return (
    <div>
      <HeaderComponent />

      <Outlet />
      <FooterComponent />
    </div>
  );
};

export default RootLayout;
