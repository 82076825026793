import IconComponent from "components/core/icon";
import React from "react";

const BlogsPage = () => {
  const BLOG_WEBSITES = [
    { title: "blogspot", redirectURL: "#", icon: "TbBrandBlogegr" },
    { title: "medium", redirectURL: "#", icon: "FaMediumM" },
    { title: "hubspot" },
  ];
  const BLOGS = [
    {
      title: "What is LYO Stays ?",
      body: "Lxuries stays for the corporate people who are thriving to sustain a balance between their work culture or work ethics and the daily vibe they sink in their lives",
      redirectURL: "#",
      id: "1",
    },
    {
      title: "What is ReSqaure? and what does it do ?",
      body: "Technlogical platform for al lkinds of real-estaet related web applications soon to be marketplace for the best of the applications.",
      redirectURL: "#",
      id: "2",
    },
  ];
  return (
    <div className="pt-20 font-jost">
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto  max-w-screen-xl lg:py-16">
          <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12 mb-8">
            <div className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-blue-400 mb-2">
              <a href="#">
                <IconComponent
                  nameIcon="TbBrandBlogger"
                  propsIcon={{ className: " cursor-pointer" }}
                />
              </a>
              <a href="#">
                <IconComponent
                  nameIcon="FaMediumM"
                  propsIcon={{ className: "ml-2 cursor-pointer" }}
                />
              </a>
              <a href="#">
                <IconComponent
                  nameIcon="FaHubspot"
                  propsIcon={{ className: "ml-2 cursor-pointer" }}
                />
              </a>
              <span className="ml-2"> Explore Blogs</span>
            </div>
            <h1 className="text-gray-900 dark:text-white text-2xl md:text-5xl font-extrabold mb-2">
              You haven't got your answers ? Explore our blogs !
            </h1>
            <p className="text-sm md:text-lg lg:text-lg font-normal text-gray-500 dark:text-gray-400 mb-6">
              Varieties of blogs describing about LYO Spaces Pvt. Ltd. with
              along with their products in details, exploring our blogs must
              resolve your all quries and gives you in-depth analysis of our
              products and company's vision.
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            {BLOGS.map((blog, idx) => {
              return (
                <div
                  key={blog.id}
                  className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12"
                >
                  <h2 className="text-gray-900 dark:text-white text-xl md:text-3xl font-extrabold mb-2">
                    {blog.title}
                  </h2>
                  <p className="text-sm md:text-lg font-normal text-gray-500 dark:text-gray-400 mb-4">
                    {blog.body}
                  </p>
                  <a
                    href={blog.redirectURL}
                    className="text-blue-600 dark:text-blue-500 hover:underline font-medium text-sm md:text-lg inline-flex items-center"
                  >
                    Read more
                    <IconComponent
                      nameIcon="BsArrowRight"
                      propsIcon={{ className: "w-4 h-4 ml-2" }}
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogsPage;
