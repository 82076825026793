import React, { useState, useEffect } from "react";
import { BsArrowBarUp, BsArrowBarDown } from "react-icons/bs";
import NitinProfile from "static/images/team/nitin_profile.png";
import RitvikProfile from "static/images/team/ritvik_profile.png";
import AyushProfile from "static/images/team/ayush_profile.png";
import SuryanshProfile from "static/images/team/suryansh_profile.png";
import LakshavyaProfile from "static/images/team/lakshavya_profile.png";
import JanviProfile from "static/images/team/janvi_profile.png";
import VikramProfile from "static/images/team/vikram_profile.png";
import AdityaProfile from "static/images/team/aditya_profile.png";

type TeamMemberObject = {
  id: number;
  title: string;
  designation: string;
  shortBreif: string;
  photo: any;
};

const TEAM: Array<TeamMemberObject> = [
  {
    id: 1,
    title: "Nitin Bansal",
    designation: "Head Of Business Development",
    shortBreif:
      "Former founder and creator of shubhyatri and fizller, in-depth ground knowledge of people and business.",
    photo: NitinProfile,
  },
  {
    id: 2,
    title: "Ritvik",
    designation: "Head Of Technology",
    shortBreif:
      "Former founder and creator of shubhyatri and fizller, ~4 Yrs of experience of building products from scratch formerly built JumboTiger Proptech.",
    photo: RitvikProfile,
  },
  {
    id: 3,
    title: "Vikram Singh",
    designation: "Head Of Management & Senior Software Developer",
    shortBreif:
      "Former founder and creator of shubhyatri , extremely good at managing things and product development.",
    photo: VikramProfile,
  },
  {
    id: 4,
    title: "Ayush Gupta",
    designation: "Head Of Management",
    shortBreif:
      "Belongs to Merchant Navy , implementing his discipline and core strength to organize thing from his profession within the company.",
    photo: AyushProfile,
  },
  {
    id: 5,
    title: "Suryansh Vashistha",
    designation: "Head Of Media Marketing",
    shortBreif:
      "Founder of Social Maven Studios and formerly build Screen Stichers , 6Yrs of experience as a DOP and Creative content creation",
    photo: SuryanshProfile,
  },
  {
    id: 6,
    title: "Lakshavya Garg",
    designation: "UI UX Designer",
    shortBreif:
      "Well versed hands in design concepts formerly part of Fizller India Pvt. Ltd. and Shubhyatri Pvt. Ltd. design team.",
    photo: LakshavyaProfile,
  },
  {
    id: 7,
    title: "Aditya Sharma",
    designation: "Photography and Media Team",
    shortBreif:
      "Formerly part of Shutter & Shadows as a core member of photography and media creation team.",
    photo: AdityaProfile,
  },
  {
    id: 8,
    title: "Janvi Jasoriya",
    designation: "Software Developer",
    shortBreif:
      "2Yrs of experience in software development and in-depth knowledge of software life-cycle",
    photo: JanviProfile,
  },
];

const AboutTeamPage = () => {
  const [selected, setSelected] = useState<TeamMemberObject>(TEAM[0]);

  const setTeamMemberInSelected = (event: string) => {
    let currentIndex = TEAM.findIndex((t, idx) => {
      return t.id === selected.id;
    });
    if (currentIndex === 0 && event === "up") {
      currentIndex = TEAM.length - 1;
    } else if (currentIndex === TEAM.length - 1 && event === "down") {
      currentIndex = 0;
    } else {
      currentIndex = event === "up" ? currentIndex - 1 : currentIndex + 1;
    }

    setSelected(TEAM[currentIndex]);
  };

  const handleUp = () => {
    setTeamMemberInSelected("up");
  };

  const handleDown = () => {
    setTeamMemberInSelected("down");
  };

  return (
    <section className="relative max-h-full font-jost">
      <div className="fixed right-8 top-1/4 md:top-1/2 border border-slate-800 pt-2 pb-2 pr-1 pl-1 rounded-full flex flex-col justify-start items-center">
        <button id="up" className="cursor-pointer">
          <BsArrowBarUp
            onClick={(e) => {
              e.stopPropagation();
              handleUp();
            }}
            className="text-slate-400 text-xl md:text-2xl"
          />
        </button>
        <button id="down" className="cursor-pointer">
          <BsArrowBarDown
            onClick={(e) => {
              e.stopPropagation();
              handleDown();
            }}
            className="text-slate-400 text-xl md:text-2xl"
          />
        </button>
      </div>
      <div className="w-screen p-4  grid grid-cols-2 ">
        <div className="col-span-2 md:col-span-1 bg-cover bg-no-repeat  w-full h-full mt-32">
          <img src={selected.photo} alt={selected.title} className="w-full" />
        </div>
        <div className="absolute backdrop-blur-sm  w-full   md:backdrop-blur-none bottom-4 left-4 md:left-1/2 col-span-2 md:col-span-1 text-slate-200 pl-4 pr-4">
          <p className="text-3xl md:text-6xl font-bold text-slate-200">
            {selected.title}
          </p>
          <p className="text-xl md:text-4xl mt-2 text-slate-200 ">
            {selected.designation}
          </p>
          {/* <p className="mt-2 text-sm md:text-md text-slate-300">
            {selected.shortBreif}
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default AboutTeamPage;
