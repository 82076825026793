import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "reducers/rootReducer";
export const rootStore = configureStore({
  reducer: {
    root: RootReducer,
  },
});

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch;
