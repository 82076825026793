import React from "react";
import IconComponent from "../icon";

const FooterComponent = () => {
  const COMPANY_LINKS = [
    {
      title: "About",
      url: "#",
    },
    {
      title: "Careers",
      url: "#",
    },
    {
      title: "Blogs",
      url: "#",
    },
  ];

  const LEGAL_LINKS = [
    { title: "Privacy Policy", url: "#" },
    { title: "Licensing", url: "#" },
    { title: "Terms & Conditions", url: "#" },
  ];
  const DOWNLOAD_LINKS = [{ title: "Web Application", url: "#" }];
  const HELP_CENTER_LINKS = [
    {
      title: "Twitter",
      url: "#",
    },
    {
      title: "Facebook",
      url: "#",
    },
    {
      title: "Contact Us",
      url: "#",
    },
  ];
  return (
    <footer className="mt-12 pb-4 pt-2 pl-3 pr-2 md:pl-24 md:pr-24 bg-transparent backdrop-blur-md backdrop-brightness-75 ">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Company
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {COMPANY_LINKS.map((cl, idx) => {
                return (
                  <li key={idx} className="mb-4">
                    <a href={cl.url} className=" hover:underline">
                      {cl.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Help center
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {HELP_CENTER_LINKS.map((hcl, idx) => {
                return (
                  <li key={idx} className="mb-4">
                    <a href={hcl.url} className="hover:underline">
                      {hcl.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Legal
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {LEGAL_LINKS.map((ll, idx) => {
                return (
                  <li key={idx} className="mb-4">
                    <a href={ll.url} className="hover:underline">
                      {ll.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Download
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {DOWNLOAD_LINKS.map((dl, idx) => {
                return (
                  <li key={idx} className="mb-4">
                    <a href={dl.url} className="hover:underline">
                      {dl.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="px-4 py-6 bg-transparent dark:bg-transparent md:flex md:items-center md:justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
            © 2023 <a href="#">LYO Spaces™ Pvt. Ltd</a>. All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center md:mt-0">
            <a
              href="#"
              className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <IconComponent
                nameIcon="RiFacebookCircleLine"
                propsIcon={{ className: "w-5 h-5" }}
              />

              <span className="sr-only">Facebook page</span>
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <IconComponent
                nameIcon="RiInstagramLine"
                propsIcon={{ className: "w-5 h-5" }}
              />

              <span className="sr-only">Instagram page</span>
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <IconComponent
                nameIcon="RiTwitterLine"
                propsIcon={{ className: "w-5 h-5" }}
              />
              <span className="sr-only">Twitter page</span>
            </a>
            <a
              href="#"
              className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <IconComponent
                nameIcon="RiGithubLine"
                propsIcon={{ className: "w-5 h-5" }}
              />
              <span className="sr-only">GitHub account</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
