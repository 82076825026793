import React from "react";
import IconComponent from "components/core/icon";
import GlobeDark from "static/images/globe.png";
import GradientTextWrapper from "components/core/gradientTextWrapper";
import PrimaryCTA from "components/core/buttons/pirmaryCTA";

const HomepageMission = () => {
  return (
    <div className="relative pl-6 pr-6 sm:pl-6 sm:pr-6 md:pl-10 md:pr-10 lg:pl-24 lg:pr-24 font-jost">
      <div className="mt-8 ">
        <p className="text-slate-200 font-jost text-xl font-bold sm:text-2xl md:text-4xl lg:text-5xl ">
          {" "}
          What are we building ?
        </p>
      </div>
      <div className="mt-6">
        <p className="text-2xl md:text-3xl lg:text-3xl">
          <a className="cursor-pointer inline-block font-extrabold text-transparent text-md bg-clip-text bg-gradient-to-r from-purple-500 to-emerald-600">
            TRUST +
          </a>
        </p>
        <p className="text-2xl md:text-3xl lg:text-3xl">
          <a className="cursor-pointer font-extrabold inline-block text-transparent text-md bg-clip-text bg-gradient-to-r from-purple-500 to-emerald-600">
            INNOVATION +
          </a>
        </p>
        <p className="text-2xl md:text-3xl lg:text-3xl">
          <a className="cursor-pointer font-extrabold inline-block text-transparent text-md bg-clip-text bg-gradient-to-r from-purple-500 to-emerald-600">
            POWER
          </a>
        </p>
      </div>
      <div className=" mt-10 ">
        <div className=" ">
          <div className="">
            <div className="font-jost text-slate-200">
              <p className="text-slate-300 text-sm md:text-lg lg:text-lg w-3/4 break-words ">
                A Technological Space, curated with indepth{" "}
                <strong>analysis</strong> and <strong>research</strong> for real
                estate.
              </p>
            </div>
            <PrimaryCTA className="mt-4">
              Our Mission{" "}
              <IconComponent
                nameIcon="BsArrowRight"
                propsIcon={{ className: "text-white inline-block ml-1" }}
              />
            </PrimaryCTA>
          </div>
        </div>
        <div className="col-span-1 -z-10 flex justify-center absolute top-0 -right-40 items-center flex-col">
          <img
            src={GlobeDark}
            className="w-80 sm:w-80 md:w-96 lg:w-100 ml-12"
            alt="tech space globe"
          />
        </div>
      </div>
    </div>
  );
};

export default HomepageMission;
