import React from "react";

// Components
HeaderNavigationComponent;
// Media Imports
import Logo from "../../../static/images/logo.png";
import HeaderNavigationComponent from "./components/navigation";
import HeaderThemeSwitcherComponent from "./components/themeSwitcher/themeSwitcher";
import { useAppSelector } from "hooks/redux/rootStoreHooks";
import PrimaryCTA from "../buttons/pirmaryCTA";
type HeaderProps = {};

const HeaderComponent: React.FC = (props: HeaderProps) => {
  const { isTransparentBackground } = useAppSelector(
    (state) => state.root.navigation
  );
  return (
    <nav
      className={`fixed w-full z-10   grid grid-cols-8 pt-4 pl-8 pr-8 md:pl-0 md:pr-0 lg:pl-0 lg:pr-0 ${
        !isTransparentBackground && ""
      }`}
    >
      <div className="inline-flex items-center  justify-end col-span-1 ">
        <img
          className="w-8 h-8 md:w-16 md:h-16 lg:w-24 lg:h-24"
          src={Logo}
          alt="Logo LYO Spaces"
          rel="Logo LYO Spaces"
          aria-aria-describedby="LYO Spaces Logo"
        />
      </div>
      <HeaderNavigationComponent wrapperClassName="ml-2 col-span-6  flex justify-center items-center" />
      <HeaderThemeSwitcherComponent wrapperClassName="ml-2 col-span-1  flex justify-left items-center" />
    </nav>
  );
};

export default HeaderComponent;
