import React, { UIEvent } from "react";
import { useAppSelector, useAppDispatch } from "hooks/redux/rootStoreHooks";
import { setNavActiveOption } from "reducers/rootReducer";
import { useMobile } from "hooks/app/useMobile";
import IconComponent from "components/core/icon";
import GradientTextWrapper from "components/core/gradientTextWrapper";
import { Link } from "react-router-dom";
interface IHeaderNavigationComponentProps {
  wrapperClassName: string;
}

interface IMobileNavigationProps {}

const MobileNavigation = (props: IMobileNavigationProps) => {
  const navigationState = useAppSelector((state) => state.root.navigation);
  const dispatch = useAppDispatch();

  const currentActiveNavOption = navigationState.activeOption;

  return (
    <div className="fixed z-50 w-full font-jost  h-16 max-w-lg -translate-x-1/2 backdrop-blur-md    bottom-0 left-1/2 ">
      <div className="grid h-full max-w-lg grid-cols-5 mx-auto">
        {navigationState.mobileOptions.map((mo, idx) => {
          return (
            <div key={idx}>
              <Link
                to={mo.redirectURL}
                data-tooltip-target="tooltip-home"
                type="button"
                id={mo.id}
                className="inline-flex flex-col items-center justify-center px-5 "
              >
                <IconComponent
                  nameIcon={mo.icon}
                  propsIcon={{
                    onClick: (e) => {
                      dispatch(setNavActiveOption(mo.id));
                    },
                    className: `w-6 h-6 mb-1 group-hover:text-blue-600 dark:group-hover:text-blue-500 ${
                      currentActiveNavOption === mo.id
                        ? "text-teal-400"
                        : "text-slate-200"
                    }`,
                  }}
                />
                <span
                  className={`text-xs block text-white ${
                    currentActiveNavOption === mo.id
                      ? "text-teal-400"
                      : "text-white"
                  }"`}
                >
                  {mo.title}
                </span>

                <span className="sr-only">{mo.title}</span>
              </Link>
              <div
                key={`div:${idx}`}
                id="tooltip-home"
                role="tooltip"
                className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
              >
                {mo.title}
                <div className="tooltip-arrow" data-popper-arrow>
                  {mo.title}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HeaderNavigationComponent = ({
  wrapperClassName,
  ...props
}: IHeaderNavigationComponentProps) => {
  const isMobile = useMobile();
  const navigationState = useAppSelector((state) => state.root.navigation);
  const currentActiveOption = navigationState.activeOption;
  const dispatch = useAppDispatch();
  const handleSelectNavOption = (e: UIEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;
    dispatch(setNavActiveOption(target.id));
  };
  return isMobile ? (
    <div className={wrapperClassName}>
      <MobileNavigation />
    </div>
  ) : (
    <div className={wrapperClassName}>
      {navigationState.options.map((navOpt, idx) => {
        return (
          <Link
            to={navOpt.redirectURL}
            onClick={handleSelectNavOption}
            className={`${
              currentActiveOption === navOpt.id
                ? "text-teal-300"
                : "text-slate-200"
            } ml-16 text-xl lg:text-3xl cursor-pointer hover:text-teal-400  `}
            key={idx}
            id={navOpt.id}
          >
            {navOpt.title}
          </Link>
        );
      })}
    </div>
  );
};

export default HeaderNavigationComponent;
