import PrimaryCTA from "components/core/buttons/pirmaryCTA";
import IconComponent from "components/core/icon";
import React from "react";
import TechGrid from "static/images/tech_grid.png";
const VisionPage = () => {
  return (
    <section
      style={{ backgroundImage: `url(${TechGrid})` }}
      className="bg-center font-jost bg-no-repeat bg-cover   relative   bg-blend-multiply"
    >
      <div className="px-4 mx-auto max-w-screen-xl text-center backdrop-blur-sm backdrop-brightness-75 py-24 lg:py-56">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
          Create, Implement & Amazing User Experience
        </h1>
        <p className="mb-8 text-sm md:text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
          We at LYO Spaces believe to implement best user experience for the
          people in real estate to easy their Management, Stay and Work Ethics.
        </p>
      </div>
    </section>
  );
};

export default VisionPage;
